import { AccessGroup } from '@/constants';

export const addAccessGroup = (accessGroups: string[] | null, newGroup: AccessGroup): string[] => {
  if (!accessGroups) {
    return [newGroup];
  }

  if (accessGroups.includes(newGroup)) {
    return accessGroups;
  }

  return [...accessGroups, newGroup];
};
