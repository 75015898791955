import { hotjar } from 'react-hotjar';
import TagManager from 'react-gtm-module';
import type { AppProps } from 'next/app';
import PageTransitionProgressBar from 'nextjs-progressbar';
import { ChakraProvider, CSSReset, ThemeProvider } from '@chakra-ui/react';
import { ClerkProvider } from '@clerk/nextjs';

import { UserProvider } from '@/components/providers';

import { GTM_ID, HJID, HJSV, PROGRESS_BAR_CONFIG } from '@/constants';
import chakraTheme from '@/theme/chakraTheme';
import { useEffectOnce } from '@/hooks';

import '@fontsource/kumbh-sans/400.css';
import '@fontsource/kumbh-sans/700.css';
import 'styles/globals.scss';

const App = ({ Component, pageProps }: AppProps) => {
  useEffectOnce(() => {
    if (GTM_ID) {
      TagManager.initialize({
        gtmId: GTM_ID,
      });
    }

    if (HJID && HJSV) {
      hotjar.initialize(HJID, HJSV);
    }
  });

  return (
    <ChakraProvider>
      <ClerkProvider>
        <UserProvider>
          <ThemeProvider theme={chakraTheme}>
            <CSSReset />
            <PageTransitionProgressBar {...PROGRESS_BAR_CONFIG} />
            <Component {...pageProps} />
          </ThemeProvider>
        </UserProvider>
      </ClerkProvider>
    </ChakraProvider>
  );
};

export default App;
