import { ApiResponseData, RequestOptions } from '@/types/api';
import { Question } from '@/types/models';
import { ApiEndpoint, NextApiEndpoint } from '@/constants';
import { getApiUrl, getNextApiUrl } from '@/utils';
import Api from './Api';

class QuestionService {
  public getQuestions = async <T extends Partial<Question> = Question>(options?: RequestOptions): Promise<T[]> => {
    const { data } = await Api.get<ApiResponseData<T[]>>(
      getApiUrl(ApiEndpoint.GetQuestions, { ...options, limit: -1 }),
    );

    return data.data.map((question) => ({ ...question, tags: question.tags ? question.tags : [] }));
  };

  public getPublicQuestions = async <T extends Partial<Question> = Question>(
    options?: RequestOptions,
  ): Promise<T[]> => {
    const { data } = await Api.get<ApiResponseData<T[]>>(
      getNextApiUrl(NextApiEndpoint.GetPublicQuestions, { ...options, limit: -1 }),
    );

    return data.data;
  };

  public getUserQuestions = async <T extends Partial<Question> = Question>(options?: RequestOptions): Promise<T[]> => {
    const { data } = await Api.get<ApiResponseData<T[]>>(
      getNextApiUrl(NextApiEndpoint.GetUserQuestions, { ...options, limit: -1 }),
    );

    return data.data;
  };

  public getQuestionById = async <T extends Partial<Question> = Question>( //
    questionId: number,
    options?: RequestOptions,
  ): Promise<T> => {
    const { data } = await Api.get<ApiResponseData<T>>(
      getApiUrl(ApiEndpoint.GetQuestion.replace('{questionId}', String(questionId)), options),
    );

    return data.data;
  };

  public getQuestionBySlug = async <T extends Partial<Question> = Question>(
    slug: string,
    options: RequestOptions = {},
  ): Promise<T> => {
    const finalOptions: RequestOptions = {
      ...options,
      filter: {
        ...(options.filter || {}),
        slug: { _eq: slug },
      },
    };

    const { data } = await Api.get<ApiResponseData<T[]>>(getApiUrl(ApiEndpoint.GetQuestions, finalOptions));

    return data.data[0];
  };

  public getPremiumQuestionBySlug = async <T extends Partial<Question> = Question>(
    slug: string,
    options: RequestOptions = {},
  ): Promise<T> => {
    const finalOptions: RequestOptions = {
      ...options,
      filter: {
        ...(options.filter || {}),
        // @ts-ignore
        slug,
      },
    };

    const { data } = await Api.get<ApiResponseData<T>>(getNextApiUrl(NextApiEndpoint.GetPremiumQuestion, finalOptions));

    return data.data;
  };

  public getQuestionWithAccess = async <T extends Partial<Question> = Question>(
    slug: string,
    options: RequestOptions = {},
  ): Promise<T | null> => {
    const finalOptions: RequestOptions = {
      ...options,
      filter: {
        ...(options.filter || {}),
        // @ts-ignore
        slug,
      },
    };

    try {
      const { data } = await Api.get<ApiResponseData<T>>(getNextApiUrl(NextApiEndpoint.GetQuestion, finalOptions));
      return data.data;
    } catch (error: unknown) {
      return null;
    }
  };
}

const questionService = new QuestionService();

export default questionService;
