import { ColorProps } from '@chakra-ui/react';

export const getPythonCaseColor = (isValidCase: boolean | null): ColorProps['color'] => {
  switch (isValidCase) {
    case null:
      return 'gray';
    case false:
      return 'red';
    default:
      return 'green';
  }
};
