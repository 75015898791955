// These are representing Directus endpoints which we query from Next.js API routes
export enum ApiEndpoint {
  Authenticate = '/auth/login/auth0',
  Logout = '/auth/logout',
  RefreshAuth = '/auth/refresh',
  GetQuestions = '/items/Questions',
  GetQuestion = '/items/Questions/{questionId}',
  GetSubmissions = '/items/Submissions',
  CreateSubmission = '/items/Submissions',
  GetComments = '/items/Comments',
  CreateComment = '/items/Comments',
  UpdateComment = '/items/Comments/{commentId}',
  GetVotes = '/items/Votes',
  CreateVote = '/items/Votes',
  GetUsers = '/users',
  GetCurrentUser = '/users/me',
  GetUserById = '/users/{userId}',
  UpdateUser = '/users/me',
  UpdateUserById = '/users/{userId}',
  GetHints = '/items/Hints',
  Files = '/files',
  GetRole = '/roles/{roleId}',
  GetCollectionMetadata = '/fields/{collection}',
  GetTags = '/items/Question_tags',
  CreateExpectedGrantees = '/items/Expected_grantees',
  GetCoupon = '/items/Coupons',
  GetProducts = '/items/Products',
  CreateSubscription = '/items/Subscriptions',
  GetSubscriptions = '/items/Subscriptions',
  GetSubscriptionById = '/items/Subscriptions/{subscriptionId}',
  UpdateSubscription = '/items/Subscriptions/{subscriptionId}',
  CreatePayment = '/items/Payments',
  GetPayments = '/items/Payments',
  GetSqlLessons = '/items/Sql_tutorials',
  GetPosts = '/items/Blog_posts',
}

// These are representing API routes which we call from the client side. Consider it as backend-for-frontend.
export enum NextApiEndpoint {
  SubmitSolution = '/submit-solution',
  SubmitPythonSolution = '/submit-python-solution',
  RunSolution = '/run-solution',
  GetUsers = '/users',
  CheckIsEmailVerified = '/check-is-email-verified',
  ResendVerificationEmail = '/resend-verification-email',
  GetUserQuestions = '/users/questions',
  UpdateUser = '/users/me',
  Subscribe = '/subscribe',
  PayIntent = '/pay',
  GetPublicQuestions = '/questions/public',
  GetQuestion = '/questions/question',
  CheckSubscription = '/check-subscription',
  GetUserCard = '/users/card',
  UpdatePaymentMethod = '/payment-method',
  GetPremiumQuestion = '/questions/premium',
  TrackCheckout = '/track-checkout',
  TrackOnRunPythonSolution = '/track-on-run-python-solution',

  //Hints
  GetHints = '/hints',

  //Prouducts
  GetProducts = '/products',

  //subscriptions
  CreateSubscription = '/subscriptions',
  GetSubscriptions = '/subscriptions',
  GetSubscriptionById = '/subscriptions',
  UpdateSubscription = '/subscriptions',
  CancelSubscription = '/subscriptions/cancel',

  //payments
  CreatePayment = '/payments',
  GetPayments = '/payments',

  //comments
  GetComments = '/comments',
  CreateComment = '/comments',
  UpdateComment = '/comments',
  GetVotes = '/votes',
  CreateVote = '/votes',

  // submissions
  GetSubmissions = '/submissions',
  CreateSubmission = '/submissions',
  LatestSubmissions = '/submissions/latest',

  GetSqlLessons = '/sql-lessons',
  GetPosts = '/blog-posts',

  // user
  GetCurrentUser = '/users/user',
  GetUserById = '/users/user',
  UpdateUserData = '/users/user',
  UpdateUserById = '/users/user',

  Files = '/users/user',

  GetCoupon = '/coupon',

  AuthCallback = '/auth/callback',
}
